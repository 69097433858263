body {
  overflow-x: hidden;
  font-family: "Graphik Web", Arial !important;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-feature-settings: "liga", "kern";
}

.homepage-cotainer {
  position: relative;
}

.homepage-cotainer .bglogo {
  min-height: 576px;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  width: 100%;
  background-size: 100%;
  max-height: 576px;
}

.homepage-cotainer .bglogo svg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
}

.homepage-cotainer .bglogo {
  background-image: url("../../assets/images/5_bgimage.png");
}

.homepage-cotainer .content-section {
  position: relative;
  z-index: 0;
}

.homepage-cotainer .content-section .brandSection {
  width: 441px;
  height: 81px;
  border: 10px solid #ff540a;
  border-top: 0px;
  border-bottom: 0px;
  margin: 0 auto;
  margin-top: 93px;
}

.homepage-cotainer .content-section .logoSection {
  max-width: 1030px;
  margin: 0 auto;
  padding-top: 40px;
  padding-left: 9px;
}

.homepage-cotainer .content-section .labelsection {
  padding-top: 54px;
  color: #fff;
  margin: 0 auto;
  padding-bottom: 5px;
}

.homepage-cotainer .content-section .labelsection .label-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
}
.homepage-cotainer .content-section .labelsection .new-tag {
  background-color: #e81159;
  text-align: center;
  height: 17px;
  font-size: 10px;
  font-weight: bold;
  width: 43px;
  line-height: 17px;
}

.homepage-cotainer .content-section .labelsection .laberHeader .header {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #012856;
  margin: 0;
  line-height: normal;
}
.homepage-cotainer .content-section .labelsection .laberHeader {
  margin: 0;
  line-height: 1.45;
  white-space: nowrap;
}

.homepage-cotainer .content-section .labelsection .laberHeader .paragraph {
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #012856;
  margin: 0;
}
.homepage-cotainer .content-section .labelsection .laberHeader .paragraph a {
  color: #012856;
  font-weight: 500;
  text-decoration:underline;
}

.homepage-cotainer .content-section .brandSection>img {
  margin: 15px 26px 12px 21px;
  width: 374px;
  height: 54px;
}

.homepage-cotainer .content-section .productcontainer{
  max-width: 1030px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 28px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.30);
  z-index: 999;
  position: relative;
}
.homepage-cotainer .content-section .productcontainer>div{
  flex: 0.5 1;
}

.homepage-cotainer .content-section .productcontainer.no-shadow{
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.0); 
}

.productBlock{
  margin-bottom: 40px;
}

.btn-link {
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  color: #0052d6;
  text-decoration: underline;
  font-weight: 400;
  padding: 0;
  margin-top: 7px;
}

.cursor{
  cursor: pointer;
}

.client-switch-modal .modal-style{
  z-index: 9999;
}

.searchsection{
  width: 830px;
  height: 72px;
  margin: 0 auto;
  margin-top: 40px;
}
.searchInput{
  border-radius: 18px;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  border: 1px solid rgba(255, 255, 255, 0.40);
  background: linear-gradient(113deg, rgba(239, 239, 239, 0.80) 0%, rgba(239, 239, 238, 0.30) 111.87%);
}
.searchInput:hover{
  background: linear-gradient(294deg, rgba(239, 239, 239, 0.80) 3.02%, rgba(239, 239, 238, 0.47) 102.87%);
}
.searchInput .tn-universal-search-container{
  width: 830px;
  height: 72px;
}
.tn-universal-search-popper{
  margin-top:12px !important;
  margin-left: 15px !important;
}
.searchInput .tn-universal-search-container .tn-icon{
  color: #012856 !important;
}
.searchInput .tn-universal-search-container .Mui-focused .tn-icon{
  color : #012856 !important;
}
.searchInput .tn-universal-search-container  input::-ms-input-placeholder { /* Edge 12-18 */
  color: #ffffff !important;
  opacity: 1;
}
.searchInput .tn-universal-search-container .MuiInputBase-input,
.searchInput .tn-universal-search-container  input::placeholder {
  color: #616161 !important;
  opacity: 1;
}
.searchInput .tn-universal-search-container  input:focus::-ms-input-placeholder {
  color: transparent !important;
  opacity: 0;
}
.searchInput .tn-universal-search-container  input:focus::placeholder {
  color: transparent !important;
  opacity: 0;
}
.tn-universal-search-popper .MuiTabs-root{
  height: 24px;
}
.homepage-cotainer .content-section .module-section{
  margin-top: 49px;
}
.content-section .project-desc{
  color: #212121;
}
.content-section .find-project{
  font-style: italic;
  margin-top: 4px;
}
.content-section .project-alerts{
  margin-top: 16px;
}
.content-section .project-alerts .alerts,
.content-section .project-alerts .my-projects{
  height: 36px;
  padding: 8px 16px;  
  border-radius: 3px;
  background-color: #f2f4f7;
  color: #012856;
  font-family:  "Graphik Web", Arial;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}
.content-section .project-alerts .alerts:hover,
.content-section .project-alerts .my-projects:hover {
  background-color: #e6eaee;
}
.content-section .project-alerts .tn-icon{
  margin-right: 8px !important;
  width: 25px;
  font-size: 20px;
}
.content-section .project-alerts .alerts{
  margin-left: 24px;
}
.project-section{
  max-width: 930px;
  margin: 40px auto 0;
}
.searchInput .tn-universal-search-container{
  background-color: #fff;
  border: solid 1px #c7cae0;
  border-radius: 15px;
} 
.homepage-cotainer .tn-logo{
  display: flex;
  justify-content: center;
  margin-top: 43px;
}
.searchInput .tn-universal-search-container .MuiInputAdornment-root{
  z-index: 100 !important;
  color: #012856;
}
.module-block{
  background-color: #f7fafe;
}
.custom-role-homepage-container{
  max-width: 930px;
  padding-top: 40px;
  margin: 0 auto;
}

.modal-open{
  overflow: hidden;
}

@media screen and (max-width: 1350px) {
  .homepage-cotainer .content-section .labelsection {
    max-width: 90%;
  }
  .homepage-cotainer .content-section .labelsection .first-label {
    white-space: nowrap;
    position: relative;
  }
  .homepage-cotainer .content-section .labelsection .label-container {
    align-items: center;
  }
  .homepage-cotainer .content-section .labelsection .new-tag {
    position: absolute;
    top: -22px;
  }
  .homepage-cotainer .content-section .labelsection .laberHeader {
    white-space: unset;
  }
}

.horizontal-row {
  height: 1px;
  background-color: #d3d3d3;
  margin: 40px auto 0px auto;
  max-width: 930px;
}

.show-banner .home-page {
  margin-top: 44px;
}

.show-banner.show-banner-60 .home-page {
  margin-top: 60px;
}