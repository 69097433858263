.new-tool {
    /* width: 100%; */
    height: 35px;
    opacity: 0.6;
    background-color: #000000;
}

.new-tool-name {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    color: #ffffff;
    display: inline-block;
}

.new-tool-accordion {
    position: relative;
    top: -30px;
}

.new-tool-accordion .ga-add02 {
    font-size: 17px;
    line-height: 17px;
    color: #ffffff;
    float: right;
    padding-top: 5px;
    cursor: pointer;
}

.accordion {
    padding: 70px 0px 0px;
}

.accordion .heading1 {
    font-weight: 600;
    margin: 0px 0px 10px;
}

.new-tool-desc {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    color: #616161;
}

.accordion-open .new-tool {
    display: none;
}