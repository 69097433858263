#userSettings .subscriptionDetails .fieldContainer{
    display: flex;
    margin-bottom: 20px;
    padding-left: 44px;
}
#userSettings .subscriptionDetails .fieldContainer .sub-label{
    flex-basis: 30%;
    color: #000000;
}
#userSettings .subscriptionDetails .fieldContainer .sub-label span{
    display:block;
}
#userSettings .subscriptionDetails .fieldContainer .sub-label span:last-child{
    font-size: 12px;
}
.tn-icon.icon-sort:before{
    color: #000000 !important;
}
#userSettings .subscriptionDetails .fieldContainer .sub-value{
    display: flex;
    flex-basis: 70%;
    color: #000000;
}
#userSettings .subscriptionSummary{
    width: 100%;
}
#userSettings .subscriptionSummary .name{
    float: left;
    width:20%;
    font-size: 14px;
    font-weight: 500;
    word-break: break-word;
}
#userSettings .Mui-expanded .subscriptionSummary .name{
    width: 90%;
}
#userSettings .subscriptionSummary .typestatus{
    float: left;
    width: 30%;
    position: relative;
}
#userSettings .subscriptionSummary .typestatus > span:last-child
{
    position: absolute;
    left: 0;
    bottom: -15px;
    font-size: 12px;
}
#userSettings .apisuite{
    float: left;
    width:40%;
}
#userSettings .subscriptionContainer{
    background: #ffffff;
    border: solid 1px #c0c0c0;
    border-top: 0px;
    padding: 10px 30px 30px 30px;
    width: 100%;
    float: left;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
#userSettings .subscriptionContainer .tn-heading3{
    border-top: solid 1px #c0c0c0;
    padding-top: 37px;
    margin-bottom: 20px;
    line-height: normal;
    font-weight: 700;
    color: #012856;
}
#userSettings .main-container.hasSubscriptions .myaccount-container{
    border-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
#userSettings .tn-accordion-container .MuiAccordionSummary-root{
    padding-left: 0px;
    min-height:57px;
}
#userSettings .tn-accordion-container {
    padding-left: 30px;
}
.subscriptionContainer .sub-header{
    display: flex;
    margin-bottom: 20px;
}
.subscriptionContainer .sub-header-name{
    float:left;
    width: 25%;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    padding-left: 38px;
}
.subscriptionContainer .sub-header-typestatus{
    float:left;
    width: 27%;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}
.subscriptionContainer .sub-header-apisuite{
    float:left;
    width: 40%;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}
#userSettings .subscriptionContainer .subscriptionDetails .emailKeyCTA{
    text-decoration: underline;
    color: #0000a9;
    font-size: 14px !important;
    font-weight: bold;
    cursor: pointer;
}

.emailKeyCTA.processing-req{
    pointer-events: none;
}

.subscriptionEmailModal .modal-text{
    margin:10px 0 90px 0;
}
.subscriptionEmailModal .button-row{
    float: right;
}

#userSettings .subscriptionDetails .fieldContainer .sub-value .credit-tooltip-wrapper {
    margin-left: 14px;
}

#userSettings .subscriptionDetails .fieldContainer .sub-value .credit-tooltip-wrapper .tn-icon {
    font-size: 17px;
    color: #616161;
    font-weight: normal;
    cursor: pointer;
}

.subscription-credits-info-tooltip .MuiTooltip-tooltip {
    height: 136px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #000;
    text-align: center;
}

.subscription-credits-info-tooltip .MuiTooltip-tooltip .MuiTooltip-arrow {
    width: 22px;
    height: 16px;
    margin-top: -16px;
    margin-bottom: -16px;
}