
.homepage-cotainer .content-section .custom-role-homepage-container{
    max-width: 930px;
    width: 100%;
    margin: 0 auto;
    display: table;
    margin-top: 40px;
    z-index: 99;
    position: relative;
    background: #ffffff;
    padding: 20px 0px 27px 0px;
    border-bottom: 1px solid #dadada;
}
  
.custom-role-homepage-container .custom-role-block-wrapper{
  display: flex;
}
.custom-role-homepage-container .custom-role-block {
  max-width: 50%;
  flex: 1 1 50%;
  height: 222px;
  padding: 0px 10px 41px 40px;
  -webkit-box-shadow: 0 0 10px 2px rgb(0 0 0 / 30%);
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 30%);
  -ms-box-shadow: 0 0 10px 2px rgb(0 0 0 / 30%);
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.30);
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.30);
}


.custom-role-homepage-container .custom-role-block.first-block{
  padding: 52px 35px;
  background-color: #f4f4f4;
}

.custom-role-homepage-container .custom-role-block .custom-role-desc-wrapper {
  margin-left: 45px;  
}

.custom-role-homepage-container .custom-role-block .custom-role-desc-wrapper .tn-heading3{
  color: #212121;
  width: 85%;
  font-size: 18px;
  margin-top: 12px;
  line-height: 23px;
}

.custom-role-homepage-container .custom-role-block  .block-first{
 margin-bottom: 35px;
 margin-top: 12px;;
}

.custom-role-block .title-icon-block{
  display: flex;
  align-items: inherit;
}


.custom-role-block .title-icon-block .producticon {
  font-size: 35px;
  color: #002856;
  padding-top: 25px;
  display: block;
}

.custom-role-block .title-icon-block .tn-heading1{
  font-weight: 700;
  font-size: 30px;
  height: 24px;
  line-height: 2.1;
  margin-left: 13px;
}

.custom-role-homepage-container .custom-role-block-wrapper .custom-role-block .custom-role-rectangle-wrapper{
  display: flex;
  height: 100%;
}

.custom-role-homepage-container .custom-role-block-wrapper .custom-role-block .custom-role-rectangle{
  margin: 53px 44px;
  padding: 21px 30px 28px 29px;
}

.custom-role-homepage-container .custom-role-block-wrapper .custom-role-block .custom-role-rectangle-wrapper .custom-role-rectangle-filler{
  /* height: 117px;
  width: 117px; */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #d2d2d2;
  background-color: #ffffff;
  margin: 0px 15px 0px 15px;
}


.custom-role-homepage-container .custom-role-block-wrapper .custom-role-block .custom-role-rectangle-wrapper .custom-role-rectangle-filler .ga{
  font-size: 60px;
}

.custom-role-block .custom-role-rectangle-wrapper .deep-blue{
  color: #012856;
}
.custom-role-block .custom-role-rectangle-wrapper .orange{
  color: #ff540a;
}
.custom-role-block .custom-role-rectangle-wrapper .blue{
  color: #009ad7;
}

.homepage-cotainer .content-section .insight-homepage-container{
  max-width: 930px;
  width: 100%;
  margin: 0 auto;
  display: table;
  margin-top: 50px;
  z-index: 99;
  position: relative;
  background: #ffffff;
  -webkit-box-shadow: 0 0 10px 2px rgb(0 0 0 / 30%);
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 30%);
  -ms-box-shadow: 0 0 10px 2px rgb(0 0 0 / 30%);
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.30);
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.30);
}

.insight-homepage-container .insight-block-wrapper{
display: flex;
}
.insight-homepage-container .insight-block {
max-width: 100%;
flex: 1 1 50%;
height: 200px;
padding: 0px 10px 41px 40px;
}
.insight-homepage-container .insight.first-block{
padding: 52px 35px;
background-color: #f4f4f4;
}

.insight-homepage-container .insight-block .insight-desc-wrapper {
margin-left: 42px;
margin-bottom: 20px;  
}

.insight-homepage-container .insight-block .insight-desc-wrapper .tn-heading3{
width: 100%;
font-weight: normal;
font-size: 18px;
color: #212121;
}

.insight-block .title-icon-block{
display: flex;
align-items: inherit;
}


.insight-block .title-icon-block .producticon {
font-size: 35px;
color: #002856;
padding-top: 30px;
display: block;
}

.insight-block .title-icon-block .tn-heading1{
font-weight: 700;
font-size: 30px;
height: 24px;
line-height: 2.1;
margin-left: 13px;
}

.custom-role-homepage-container .tile-block {
  max-width: 100%;
  flex: 1 1 33%;
  padding: 0px 8px;
  text-align: center;
  border-right: 1px solid #d3d3d3;
}

.tile-block .title-wrapper {
  margin-top: 20px;
}

.title-icon-block .icon {
  font-size: 44px;
  color: #012856;
  display: block;
}

.title-icon-block .title-wrapper .tn-heading1{
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #002856;
}

.tile-block .tile-desc-wrapper {
  margin: 24px 1px 28px 1px;
}

.title-icon-block .title-wrapper .tn-heading3 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #212121;
}

.custom-role-block-wrapper > div:last-child {
  border-right: none;
} 

.custom-role-homepage-container .single-product-access {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.single-product-access .title-icon-block {
  display: flex;
  gap: 12px;
}

.single-product-access .title-wrapper .tn-heading1 {
  text-align: start;
}

.tile-block .tn-heading3 {
  margin: 12px 1px 28px 1px;
}

.custom-role-block-wrapper .tile-block .MuiButton-root {
  position: unset;
}