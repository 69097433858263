.root-loading{
    display: flex !important;
    justify-content: center !important;
    position: fixed;
    width: 100%;
    height: 100%;
}

.button-center {
	left: 50%;
	transform: translate(-50%, 0%);
	position: absolute;
    bottom: 172px;
}

.main-container.padding-right-25 {
	padding-right: 25px;
}

.main-container.padding-left-25 {
	padding-left: 25px;
}

.main-container.padding-left-65 {
	padding-left: 65px;
}

.padding-right-0 {
	padding-right: 0px;
}

.main-container.hide-tnp-section .data-settings-section.right{
	display: none;
}

/* FOOTER */
.home-container {
	height: 500px;
}

.legacy-access{
	height: 60px;
	background-color: #f4f4f4;
	text-align: right;
}

.legacy-text {
	margin-top: 18px;
	margin-right: 25px;
	display: inline-block;
	line-height: 23px;
	color: #002856;
}

.legacy-text .bold {
	font-weight: 500;
}

header.navbar{
  min-height: auto;
  border : 0px;
  border-bottom: none !important;
  z-index: unset!important;
}

#root .footer-edits{
	margin-top:91px 
}


/******* Dropdown Form ****/
.dropdown-medium{
	height: 36px;

}
.dropdown-large{
	height: 44px;
	font-size: 24px;
}

.ga {
	display: inline-block;
	color:inherit;
}
.ga.ga-close01{
	cursor: pointer;
}

/******* modal style ****/
.modal-style {
	width: 580px;
	position: fixed;
	top: 130px;
	left: 50%;
	transform: translate(-50%, 0%);
	z-index: 50;
	background: #ffffff;
	border: solid 2px #d3d3d3;
	box-shadow: 0 1px 3px 0 rgba(97, 97, 97, 0.28)!important;
	padding: 15px 15px 15px 15px!important;
	text-align: left;
	border-radius: unset;
}

.modal-body label{
	margin: unset;
}

.modal-body .heading4{
	margin-bottom: 10px;
}

.prompt .modal-style{
	width: 480px;
	box-shadow: 0 1px 10px 0 #616161 inset;
}

.modal-style h2, .modal-style h3, .modal-style h4{
	padding:0;
	margin: 0;
}

.modal-style .checkbox-label{
	margin : 0px;
	text-align: left;
}

.modal-style .checkbox-label i{
	margin-right:5px;
}

.modal-header{
	padding: 0 0 10px 0!important;
	margin: 0;
	border-bottom: none;
}

.modal-text{
	color:#616161;
}

.modal-body{
	padding: 20px 0 0 0;
}

.modal-style .button-row {
	text-align: right;
	margin-top: 15px;
}

.button-row .btn{
	margin-left: 10px!important;
	padding: 6.5px 20px;
}


.button-row .btn.btn-disabled{
	padding: 8.5px 20px;
	border-left: 2px solid #d3d3d3;
    border-right: 2px solid #d3d3d3;
}

.modal-footer{
	padding: 0!important;
}

.backdrop-style {
	position: fixed;
	width: 100%;
	top: 0px;
	left: 0px;
	z-index: 9998;
	background: rgba(33, 33, 33, 0.8);
}

#userSettings{
	height: auto !important;
    margin-bottom: 0 !important;
	width: 100%;
    float: left;
	padding-bottom: 100px;
}

.tn-footer-wrapper{
	width: 90% !important;;
    min-width: 1030px !important;
    max-width: 1430px !important;
    margin-right: auto;
    margin-left: auto;
}

#userSettings + .footer-container .tn-footer-wrapper{
    padding-right: 35px !important;
    padding-left: 35px !important;
}

.tn-footer-wrapper a:hover{
	color: inherit !important;
 }

.subscriptionEmailModal .tn-heading3{
    font-weight: 700 !important;
	line-height: 23px;
}
.subscriptionEmailModal .modal-text {
	line-height: 23px;
	color: #212121;
}
