.insight-container {
    max-width: 930px;
    width: 100%;
    margin: 0 auto;
    display: table;
    margin-top: 24px;
    background: #ffffff;
    padding: 20px 0px 27px 0px;
}

.insight-header {
    display: flex;
    align-items: flex-end;
}

.insight-header .icon {
    font-size: 51px;
    width: 51px;
    height: 48px;
    color: #012856;
    text-align: center;
}
.insight-header .insight-title {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #002856;
}

.insight-section-container {
    display: flex;
    margin: 20px 0px 23px 0px;
}

.insight-section-block {
    height: 263px;
    flex-grow: 0;
    margin-top: 20px;
    margin-right: 18px;
    padding: 0 0 8px;
    background-color: #ffffff;
    max-width: 33%;
    flex: 1 1 33%;
}

.insight-section-block img {
    display: block;
    width: 296.9px;
    height: 191px;
    border-radius: 12px;
}

.insight-section-block:hover {
    cursor: pointer;
    color: #0000d3;
    font-size: 16px;
}

.insight-section-block:hover img {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.11), 0 2px 8px 0 rgba(0, 0, 0, 0.14), 0 4px 10px 0 rgba(0, 0, 0, 0.15);
}

.insight-section-block .header {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin: 8px 4px 4px 4px;
}

.insight-section-block .date {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #616161;
    margin: 0px 4px 8px 4px;
}

.insight-section-button {
    display: flex;
    justify-content: center;
}

.insight-section-button .MuiButton-root {
    position: unset;
}

.insight-section-container> div:last-child {
    margin-right: 0px;
  }