.module-container{
    display: flex;
    flex-wrap: wrap;
    max-width: 930px;
}

.module-card-container {
    max-width: 930px;
    margin: 0 auto;
}
.module-container .module-card .tn-icon{
    font-size: 40px;
    color: #012856;
}
.module-container .module-card {
    width: 293px;
    height: 235px;
    padding: 24px 20px;
    margin: 0px 24px 24px 0;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    background-color: #ffffff;
}
.module-container .module-card:nth-child(3n) {
    margin-right: 0px;
}
.module-container .module-card:hover {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.11), 0 2px 8px 0 rgba(0, 0, 0, 0.14), 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
}
.module-container .module-card:hover .open-btn, .module-container .module-card:hover .req-btn{
    background-color: #e6eaee;
}
.module-container .module-card .modulecard-footer{
    display: flex;
    margin-top: 16px;
    justify-content: space-between;
}
.module-container .module-card .tn-heading3{
    margin-top: 4px;
    color: #012856;
}
.module-container .module-card .module-desc{
    height: 57px;
    margin-top: 8px;
    color: #000;
    font-family: "Graphik Web", Arial;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
}
.module-container .module-card .module-desc-evp{
    height: 57px;
    margin-top: 8px;
    color: #000;
    font-family: "Graphik Web", Arial;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
}
.request-modal .modal-title{
    display: flex;
    align-items: center;
}
.request-modal .modal-title .tn-icon{
    font-size: 28px;
    color: #012856;
    margin-right: 8px;
}
.request-modal .modal-desc{
    margin: 13px 0 66px 0;
    color: #212121;
    font-size: 14px;
}

.module-container .access-module{
    display: flex;
}
.module-section .module-division{
    border-bottom: 1px solid #d3d3d3;
    width: 930px;
    margin: 3px auto 28px;
    height: 1px;
}
.request-modal .tn-structured-modal-body{
    margin-top: 10px !important;
}

.modulecard-footer .modulecard-footer-text {
    max-width: 125px;
}

.modulecard-footer .MuiButton-root {
    border-radius: 3px;
    padding: 9.5px 16px;
    background-color: #f5f6f8;
}

.module-header-text {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #414141;
    margin-bottom: 12px;
}

.modulecard-footer .no-access-modulecard-footer-text {
    max-width: 115px;
    color: #212121;
}

.no-access.module-card-container .module-card .tn-heading3 {
    color: #212121;
}

.no-access.module-card-container .module-card .tn-icon{
    color: #212121;
}

.modulecard-footer .open-btn:hover,
.modulecard-footer .req-btn:hover {
    background-color: #e6eaee;
}