.new-tool-img {
  position: relative;
    bottom: 35px;
    width: 490px;
    height: 269px;
    float: right;
}

.btn-link{
   display: inline-block;
    font-size: 14px;
    line-height: 19px;
    color: #0052d6;
    text-decoration: underline;
    font-weight: 400;
    padding: 0;
    margin-top: 7px;
    cursor: pointer;
}

.accordion .heading1 > sup {
    font-size: 16px;
    font-weight: bold;
    color: #ff540a;
    margin-left: -4px;
}