.majorAnnouncement .modal-style{
    width: 580px;
    height: 172px;
    border: solid 2px #d3d3d3;
    background-color: #ffffff;
    padding: 15px 15px 20px 15px;
}
.majorAnnouncement .modal-header .heading3{
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: normal;
    color: #0b2f62;
}
.majorAnnouncement .modal-header{
    margin-bottom: 15px;
}

.majorAnnouncement .modal-text{
    margin-bottom:20px;
}


.majorAnnouncement .button-row{
    justify-content: center;
    display: flex;
}


