.talent-intelligence.main-container {
    max-width: 1200px;
}

.logo-header a {
    display: inline-block;
    color: #0700a3;
}

.talent-intelligence-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 70px;
}

.talent-intelligence-section .section-left {
    width: 50%;
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.tn-header {
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    color: rgb(26, 44, 85);
    text-align: start;
}

.tn-paragraph {
    font-size: 16px;
    color: #000;
    line-height: 24px;
    text-align: start;
}

.talent-intelligence-section .section-right {
    width: 50%;
}

.talent-intelligence-section .img {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px 0px;
}

.talent-intelligence-section .api-button {
    height: 46px;
    font-size: 16px;
    font-weight: 400;
    padding: 15px 30px;
}

.tn-sub-header {
    font-size: 19px;
    font-weight: 400;
    line-height: 32px;
    color: #000;
    text-align: start;
    margin-bottom: 14.4px;
}

.modal-container .close-icon {
    box-shadow: none;
}

.modal-container .button-div {
    display: flex;
    justify-content: flex-end;
}

.modal-container .close-icon:hover {
    border-width: 1;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16), 0 1px 18px 0 rgba(97, 97, 97, 0.12), 0 6px 10px 0 rgba(97, 97, 97, 0.14);
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.padding-bottom-0 {
    padding-bottom: 0;
}

.talent-intelligence-footer {
    padding: 25px 0px 30px 0px;
    text-align: center;
}

.talent-intelligence-footer .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 14px;
}

.talent-intelligence-footer .footer .phone {
    border-right: 1px solid;
    border-left: 1px solid;
    padding: 0px 8px;
}

.talent-intelligence-footer .footer>p {
    line-height: 1;
    font-size: 17px;
    color: #000;
}

.talent-intelligence-footer .linked-in a {
    background: #0700A3;
    width: 30px;
    height: 30px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.talent-intelligence-footer .linked-in a:hover {
    transform: scale(1.1);
    text-decoration: none;
}

.tn-checkbox svg {
    width: 24px !important;
    height: 24px !important;
}

.modal-container .content {
    margin: 15px 0px 25px 0px;
}

.modal-container .content .header2 {
    color: #1A2C55;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2em;
    text-align: center;
    padding-bottom: 20px;
}

.modal-container .content .paragraph {
    text-align: center;
    color: #333333;
    font-size: 18px;
}

.universal-search-header-container {
    display: flex;
    align-items: center;
    padding: 12px 20px 12px 60px;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    background: #fff;
    height: 60px;
    margin-bottom: 70px;
}

.universal-search-header-container .logo-header {
    padding-left: 20px;
    height: 22px;
}

.universal-search-header-container .tn-universal-search-container {
    margin: 0 auto;
}

.universal-search-header-container .tn-universal-search-container .MuiFormControl-root {
    border-radius: 9px;
    outline: 2px solid #C7CAE0;
    margin-top: 0;
    padding: 0px 20px;
    height: 36px;
}

.universal-search-header-container .tn-universal-search-container .MuiFormControl-root .MuiInputBase-root {
    padding: 8px 0px;
}

.universal-search-header-container .tn-universal-search-container .MuiFormControl-root .MuiInputBase-root .MuiInput-input {
    padding-right: 20px;
    font-size: 16px;
}

.universal-search-header-container .tn-universal-search-container .MuiFormControl-root .MuiInputBase-root .MuiInputAdornment-root {
    z-index: 0;
    margin: 0;
}

.universal-search-header-container .tn-universal-search-container input::-ms-input-placeholder {
    opacity: 1;
}

.universal-search-header-container .tn-universal-search-container .MuiInputBase-input,
.universal-search-header-container .tn-universal-search-container input::placeholder {
    opacity: 1;
}

.universal-search-header-container .tn-universal-search-container input:focus::-ms-input-placeholder {
    color: transparent !important;
    opacity: 0;
}

.universal-search-header-container .tn-universal-search-container input:focus::placeholder {
    color: transparent !important;
    opacity: 0;
}

.talent-intelligence-api .tn-universal-search-popper {
    margin-left: 15px !important;
    margin-top: -6px !important;
    background-color: #ffff;
}

@media (max-width: 1240px) {
    .universal-search-header-container .tn-universal-search-container {
        width: 575px !important;
    }

    .talent-intelligence-api .tn-universal-search-popper .popper-content {
        width: 575px !important;
    }
}

@media (min-width: 1240px) {
    .universal-search-header-container .tn-universal-search-container {
        width: 661px !important;
    }

    .talent-intelligence-api .tn-universal-search-popper .popper-content {
        width: 661px !important;
    }
}

.universal-search-header-container .tn-universal-search-container .MuiInputBase-root .MuiInputAdornment-root .tn-icon.icon-magnifying-glass-search {
    font-size: 20px;
}

.universal-search-header-container .tn-announcement-icon {
    right: 0px;
    top: 0px;
    position: relative;
}

.universal-search-header-container .tn-announcement-banner {
    left: 0px;
    position: fixed;
    top: 0px;
}